/* Definição da área de trabalho */
@keyframes fade{
    from{opacity: 0;}
    to{opacity: 1;}
}

.App{
    overflow-x: hidden;
    width: 100%;
    background:
        linear-gradient(
        #ffffffaa,#ffffffaa
        ),
        url(./imgs/17580.jpg);
        background-size: contain;    
}

/* Menu */

.Nav{
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 30px;
    right: 200px;
    font-family: 'Poppins';
    font-size: small;
}

.NavItem{
    color: #514D4C;
    border: none;
    cursor: pointer;
}

.NavItem a{
    color: #514D4C;
    border: none;
    padding: 0;
    padding: 0 20px 0 20px;
    height: 50px;
    display: flex;
    align-items: center;
}

.NavItem a:hover{
    color: #514D4C;
    border: 0px solid #fff;
    padding: 0 21px 0 21px;
}

.active{
    font-weight: bold;
}

@media only screen and (max-width: 800px) {
    .Nav{
        position: absolute;
        top: 90px;
        right: 30px;
        left: 160px;
    }
    .NavItem{
        width: 100%;
        background-color: #ffffffcc;
    }
}

/* Logo */

.Logo{
    height: 50px;
    position: absolute;
    top: 30px;
    right: 40px;
}

.LogoCel{
    height: 50px;
    position: absolute;
    top: 30px;
    right: 40px;
}

/* Redes Sociais */

.icones{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: space-between;
    top: calc(50vh - 100px);
    left: 30px;
}

/* Definição das páginas */

.Home{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    padding: 0 40px 0 80px;
    justify-content: space-between;
    text-align: right;
}

.Home *{
    animation-name: fade;
    animation-duration: 2s;
}

.Home h1{
    font-size: 60px;
}

.Home h3{
    font-size: medium;
    font-family: 'PoppinsLight';
    line-height: 1.8rem;
    margin-bottom: 15px;
}

.Home .img{
    width: 45%;
    height: 100vh;
    background: url(./imgs/4502485.png);
    background-size: cover;
    background-position: center;
}

.Home .conteudo{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.info{
    position: absolute;
    bottom: 40px;
    right: 40px;
    width: 50%;
}

.iconeTel{
    width: 30px;
    height: 30px;
    background: url(./imgs/icons/Phone.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.iconeLoc{
    width: 30px;
    height: 30px;
    background: url(./imgs/icons/Location.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.tel{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: 'PoppinsLight';
}

.Home .txt2{
    color: #AFD505;
    font-family: 'Poppins';
}

.Home .txt{
    width: calc(100% - 40px);
    font-size: small;
    color: #8E8D8B;
}

.tel2{
    margin-top: 10px;
}

h1{
    font-family: 'PoppinsExtraBold';
    color: #AFD505;
}

.menuCel{
    display: none;       
}

/* Sobre */

.Sobre{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 10%;
}

.Sobre *{
    animation-name: fade;
    animation-duration: 2s;
}

.sobre{
    width: 40%;
}

.sobre p{
    font-family: 'PoppinsLight';
    font-size: 14px;
    padding: 0;
    margin: 0;
    margin-top: 15px;
    line-height: 1.4rem;
}

.traco{
    height: 2px;
    border-radius: 1px;
    width: 80%;
    background: #AFD505;
}

.imgSobre{
    width: 50%;
    background: url(./imgs/sobre.png);
    background-size: cover;
    height: 100vh;
    background-repeat: no-repeat;
}

.mvv{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 10%;
    padding-right: 10%;
    justify-content: space-between;
    margin-top: -50px;
    padding-top: 50px;
    background: linear-gradient(#AFD505,#AFD50566);
    padding-bottom: 70px;
}

.MVV{
    width: 300px;
    font-family: 'PoppinsLight';
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.MVV p{
    font-size: small;
    line-height: 1.4rem;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 15px;
    text-align: center;
    color: #8E8D8B;
}

.MVV h3{
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 25px;
    color: #AFD505;
}

.cardd{
    font-size: 70px;
    width: 280px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: url(./imgs/card1.jpg);
    border-radius: 8px 8px 0 0;
    margin-left: 10px;
}

.carddbody{
    width: 260px;
    border-bottom: 2px solid #ddd;
    padding: 0 15px 0 15px;
    background: #fff;
}

.esquerda{
    background: #f6f6f6;
    width: 20px;
    height: 280px;
    transform:skewy(45deg);
    margin-top: -10px;
}

.direita{
    background: #f6f6f6;
    width: 20px;
    height: 280px;
    transform:skewy(-45deg);
    margin-top: -10px;
}

.Categorias{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    padding:50px 10% 50px 10%;
    -webkit-box-shadow: 0px 0px 20px 15px #fff; 
    box-shadow: 0px 0px 20px 15px #fff;
}

.Categorias h1{
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
    font-family: 'PoppinsExtraBold';
}

.Categorias h3{
    color: #AFD505;
    font-family: 'Poppins';
    font-size: x-large;
    margin: 0;
    padding: 0;
    margin-bottom: 15px;
}

.Categorias p{
    font-family: 'PoppinsLight';
    font-size: 14px;
    color: #8E8D8B;
    margin: 0;
    padding: 0;
}

.Categorias .cat{
    width: 31%;
    padding: 25px;
    border: 1px solid #ccc;
    background: #ffffff33;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 8px;
    cursor: context-menu;
}

.Categorias .cat:hover{
    width: 33%;
    -webkit-box-shadow: 5px 5px 15px 5px #bbb; 
    box-shadow: 5px 5px 15px 5px #bbb;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

.imgCat{
    width: 150px;
    height: 150px;
    background: #8E8D8B;
    border-radius: 75px;
    margin-bottom: 25px;
}

.cat1{
    background: url(./imgs/cat1.jpg);
    background-size: cover;
    background-position: center;
}

.cat2{
    background: url(./imgs/cat2.jpg);
    background-size: cover;
    background-position: center;
}

.cat3{
    background: url(./imgs/cat3.jpg);
    background-size: cover;
    background-position: center;
}

/* Serviços */
.Servicos{
    display: flex;
    flex-direction: row;
    padding: 0 40px 0 5%;
    align-items: center;
    height: 100vh;
    justify-content: space-between;
}

.Servicos *{
    animation-name: fade;
    animation-duration: 2s;
}

.Servicos h1{
    text-align: right;
    margin-bottom: 20px;
}

.Servicos p{
    text-align: right;
    font-family: 'PoppinsLight';
    font-size: 14px;
    line-height: 23px;
}

.imgServicos{
    width: 50%;
    height: 100vh;
    background: url(./imgs/fundosvc.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.Servico{
    width: 45%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;
}

.Servicos a{
    background: #AFD505;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
    border: none;
    color: #fff;
    margin-top: 15px;
    font-family: 'PoppinsLight';
}

.Servicos a i{
    margin-right: 5px;
}

.Servicos .vermais:hover{
    text-decoration: none;
    background: #514D4C;
    color: #fff;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

.SVC{
    background:
        linear-gradient(
        #AFD505cc,#AFD505ee
        ),
        url(./imgs/fundosvc2.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    margin-top: -50px;
    padding: 50px 10% 50px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.SVC h1{
    margin-top: -10px;
    padding-top: 10px;
    margin-bottom: 30px;
    padding-bottom: 10px;
    color: #fff;
    text-align: center;
    background: linear-gradient(#AFD505cc,#AFD505cc);
}

.servicoCards{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.servicoCards .servico{
    width: 33%;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    padding: 20px;
    background: #AFD505;
}

.servicoCards .servico h3{
    font-family: 'Poppins';
    font-size: 20px;
}

.servicoCards .servico i{
    color: #fff;
    font-size: 36px;
    margin-bottom: 15px;
}

.servicoCards .servico p{
    color: #fff;
    margin-bottom: 10px;
    font-family: 'PoppinsLight';
    font-size: 14px;
    line-height: 1.6rem;
}

.s1{
    border-radius: 8px 0 0 0;
}

.s3{
    border-radius: 0 8px 0 0;
}

.s4{
    border-radius: 0 0 0 8px;
}

.s6{
    border-radius: 0 0 8px 0;
}

/* Rodapé */

.Footer{
    width: 100%;
    padding: 30px 0 30px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    flex-wrap: wrap;
}

.Footer .rod{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.fotoRodape{
    width: 150px;
    height: 50px;
    margin-bottom: 20px;
}

.Footer .iconesFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 200px;
}

.Footer .center{
    border-left: 2px solid #AFD505;
    border-right: 2px solid #AFD505;
    font-family: 'PoppinsLight';
    width: 50%;
}

.Footer .center h2{
    font-size: 36px;
    color: #AFD505;
}

.Footer .center h4{
    font-size: large;
    color: #AFD505;
}

.Footer .center i{
    color: #AFD505;
    margin-right: 5px;
}

.Footer .center p{
    padding: 0;
    margin: 0;
    margin-top: 10px;
    font-size: small;
}

.Footer .right{
    width: 25%;
}

.Footer .left{
    width: 25%;
}

/* Renderização responsiva do menu */

@media only screen and (max-width: 800px) {

    /* menu */
    .menu{
        display: none;
    }
    .menuCel{
        display: block;
        position:absolute;
        margin: 30px;
        z-index: 99;
        width: 100%;
    }
    .menuCel button{
        border-radius: 4px;
        border: solid 4px #AFD505;
        color: #AFD505;
    }
    .openMenu{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .openMenu button{
        border: none;
        border-radius: 0px;
        font-size: medium;
        padding: 10px;
        width: 60%;
        background-color: #ffffffee;
    }

    /* logo */
    .LogoCel{
        display: none;
    }

    /* home */
    .Home{
        flex-wrap: wrap;
        height: 100vh;
        padding: 0;
    }

    .Home .img{
        width: 100%;
        height: 100vh;
        background: url(./imgs/4502485.png);
        background-size: cover;
        background-position: center;
    }
    
    .Home .conteudo .texto{
        background-color: #ffffffee;
        position: absolute;
        width: 70%;
        top: 100px;
        right: 0;
        padding: 20px;
    }

    .Home .conteudo .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #ffffffee;
        width: 70%;
        right: 0;
        padding: 20px;
    }

    .Home .conteudo h1{
        font-size: x-large;
    }
    .Home .conteudo h3{
        font-size: 12px;
        color: #000;
    }
    .Home .txt{
        font-size: 11px;
        color: #8E8D8B;
    }

    /* sobre */
    .Sobre .sobre{
        position: absolute;
        width: 70%;
        left: 90px;
        background-color: #ffffffbb;
        padding: 5px;
    }

    .Sobre .sobre h1{
        font-size: medium;
    }

    .Sobre .sobre p{
        font-size: 11px;
    }

    .Sobre .imgSobre{
        width: 100%;
    }

    .mvv{
        flex-wrap: wrap;
    }
    
    .MVV .esquerda{
        display: none;
    }

    .MVV .direita{
        display: none;
    }
    .MVV .cardd{
        margin: 0;
    }
    .MVV .carddbody{
        margin: 0;
        margin-left: 10px;
        border-bottom: 2px solid #ddd;
        padding: 0 15px 0 15px;
    }

    .Categorias{
        flex-wrap: wrap;
    }
    .Categorias .cat{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    /* Servicos */
    .Servicos .imgServicos{
        width: 100%;
        height: 100vh;
    }
    .Servicos .Servico{
        position: absolute;
        width: 70%;
        left: 90px;
        background-color: #ffffffbb;
        padding: 5px;
    }
    .Servicos .Servico h1{
        font-size: x-large;
    }
    .Servicos .Servico p{
        font-size: small;
    }
    .SVC .servico{
        border-radius: 0px;
        width: 100%;
    }
    .SVC .s1{
        border-radius: 6px 6px 0 0;
    }
    .SVC .s6{
        border-radius: 0 0 6px 6px;
    }

    /* equipe */
    .Equipe .imgEquipe{
        width: 100%;
        height: 100vh;
    }
    .Equipe .conteudo{
        width: 70%;
        position: absolute;
    }

    /* footer */
    .Footer .rod{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .Footer .center{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .Home *{
        animation-name: fade;
        animation-duration: 0.1s;
    }
    .Sobre *{
        animation-name: fade;
        animation-duration: 0.1s;
    }
    .Servicos *{
        animation-name: fade;
        animation-duration: 0.1s;
    }
}

@media only screen and (min-width: 800px) {
    
}