.toggle{
    width: 100%;
}

.toggle button{
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 0 20px 0;
}

.toggle h3{
    color: #fff;
    font-size: x-large;
    margin: 0;
    padding: 0;
}