.Equipe{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 40px 0 10%;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
}

.Equipe *{
    animation-name: fade;
    animation-duration: 2s;
}

.Equipe .conteudo{
    width: 40%;
}

.Equipe .imgEquipe{
    width: 50%;
    height: 80vh;
    margin-top: 35px;
    background: url(../../imgs/fundoequipe.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
}

.Equipe a{
    background: #AFD505;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
    border: none;
    color: #fff;
    margin-top: 15px;
    font-family: 'PoppinsLight';
}

.Equipe a i{
    margin-right: 5px;
}

.Equipe h1{
    margin-bottom: 20px;
}

.Equipe p{
    font-family: 'PoppinsLight';
    margin-bottom: 30px;
    line-height: 1.7rem;
}

.Equipe .vermais:hover{
    text-decoration: none;
    background: #514D4C;
    color: #fff;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

@media only screen and (max-width: 800px) {
    .Equipe{
        text-align: right;
    }

    .Equipe *{
        animation-name: fade;
        animation-duration: 0.1s;
    }
    
    .Equipe .imgEquipe{
        width: 100%;
        height: 100vh;
    }
    .Equipe .conteudo{
        width: 70%;
        position: absolute;
        background-color: #ffffffcc;
        left: 80px;
        padding: 20px;
    }
}

