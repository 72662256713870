.Contato{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 0 0;
}

.Contato *{
    animation-name: fade;
    animation-duration: 2s;
}

.Contato .imgContato{
    width: 40%;
    padding-top: 75px;
}

.Contato p{
    font-family: 'PoppinsLight';
}

.Contato .conteudo{
    width: 50%;
    height: 100vh;
    background: url(../../imgs/OBJECTS.png);
    background-repeat: no-repeat;
    background-position-y: 250px;
    background-position-x: -50px;
}

.Contato .texto{
    position: absolute;
    left: 20%;
    top: 30%;
    padding: 20px 20px 0 80px;
    background: url(../../imgs/uni.png);
    background-size: contain;
    background-repeat: no-repeat;
    height: 270px;
    width: 400px;
}

.Contato .texto p{
    margin-top: 20px;
}

form{
    background:#eeeeeeaa;
    padding: 0 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    align-items: flex-start;
}

.Contato textarea{
    width: 100%;
    padding: 8px;
    font-family: 'PoppinsLight';
    margin-top: 20px;
    height: 120px;
    font-size: small;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.Contato input{
    width: 100%;
    padding: 8px;
    font-family: 'PoppinsLight';
    margin-top: 20px;
    font-size: small;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.Contato .vermais{
    background: #AFD505;
    padding: 10px 20px 10px 20px;
    font-size: small;
    border-radius: 8px;
    border: none;
    color: #fff;
    margin: 20px 0 20px 0;
    font-family: 'PoppinsLight';
}

.Contato .vermais i{
    margin-right: 5px;
}

.Contato .vermais:hover{
    text-decoration: none;
    background: #514D4C;
    color: #fff;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

@media only screen and (max-width: 800px) {
    .Contato{
        padding: 0;
    }
    .Contato *{
        animation-name: fade;
        animation-duration: 0.2s;
    }
    .Contato .texto{
        display: none;
    }
    .Contato .imgContato{
        width: 70%;
        padding: 0;
        position: absolute;
        right: 0;
        top: 30px;
        background: #ffffffbb;
    }
    .Contato .conteudo{
        width: 100%;
        height: 100vh;
        background: url(../../imgs/OBJECTS.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
    }
    form{
        background: transparent;
    }
}