.IconSocial{
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: #fff;
    border: 3px solid #AFD505;
}

.IconSocial i{
    color: #AFD505;
}

.IconSocial:hover{
    transform: rotate(360deg);
    -webkit-transition: all 1.5s 0s ease;
    -moz-transition: all 1.5s 0s ease;
    -o-transition: all 1.5s 0s ease;
    transition: all 1.5s 0s ease;
}