.Equipes{
    background:
        linear-gradient(
        #AFD505cc,#AFD505ee
        ),
        url(../../imgs/fundosvc2.jpg);
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
    margin-top: -50px;
    padding: 70px 10% 70px 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.titulo{
    width: 100%;
}

.Equipes h1{
    margin-bottom: 40px;
    width: 50%;
    margin-left: 25%;
    color: #fff;
    text-align: center;
}

.equipescard{
    width: 45%;
    border: 5px solid #afd5058e;
    background: #ffffffee;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    justify-content: center;
}

.imgequipes1{
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-image: url(../../imgs/ananunes.jpeg);
    background-size: cover;
    margin-bottom: 15px;
}

.Equipes p{
    font-family: 'PoppinsLight';
    font-size: small;
    line-height: 1.6rem;
    text-align: center;
}

.Equipes h4{
    font-family: 'Poppins';
    font-size: 24px;
    text-align: center;
}

.Equipes h5{
    font-family: 'PoppinsItalic';
    font-size: medium;
    text-align: center;
    margin: 10px 0 10px 0;
    color: #8E8D8B;
}

.redesequipes{
    width: 100%;
    text-align: center;
}

.redesequipes a{
    color: #AFD505;
    font-size: 24px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 0 5px 0 5px;
}

.redesequipes a:hover{
    color: #514D4C;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

@media only screen and (max-width: 800px) {
    .equipescard{
        width: 100%;
    }
}