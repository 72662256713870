@font-face {
    font-family: 'Poppins';
    src: url(./fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'PoppinsExtraBold';
    src: url(./fonts/Poppins-ExtraBold.ttf);
}

@font-face {
    font-family: 'PoppinsLight';
    src: url(./fonts/Poppins-Light.ttf);
}

@font-face {
    font-family: 'PoppinsItalic';
    src: url(./fonts/Poppins-LightItalic.ttf);
}

html{
    scroll-behavior: smooth;
    -webkit-overflow-scrolling:touch;
    overflow-x: hidden;
    color: #514D4C;
}

/* #AFD505 - verde */
/* #514D4C - cinza */
/* #8E8D8B - cinza claro */