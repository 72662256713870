.Endo{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 40px 0 0;
    justify-content: space-between;
    text-align: right;
}

.Endo *{
    animation-name: fade;
    animation-duration: 2s;
}

.imgEndo{
    width: 55%;
    height: 100vh;
    background: url(../../imgs/endo2.png);
    background-size: cover;
    background-position: center;
}

.Endo .conteudo{
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-top: 10px;
}

.Endo h1{
    margin-bottom: 20px;
}

.Endo p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'PoppinsLight';
    line-height: 1.7rem;
    margin-bottom: 15px;
}

.Endo a{
    background: #AFD505;
    padding: 10px 25px 10px 25px;
    border-radius: 20px;
    border: none;
    color: #fff;
    margin-top: 10px;
    font-family: 'PoppinsLight';
}

.Endo a i{
    margin-right: 5px;
}

.Endo .vermais:hover{
    text-decoration: none;
    background: #514D4C;
    color: #fff;
    -webkit-transition: all 0.7s 0s ease;
    -moz-transition: all 0.7s 0s ease;
    -o-transition: all 0.7s 0s ease;
    transition: all 0.7s 0s ease;
}

/* ritual */

.Ritual{
    background: url(../../imgs/fundoritual.png);
    background-size: cover;
    width: 100%;
    height: 100vh;
    margin-top: -40px;
    padding: 0 10% 0 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.Ritual .conteudo{
    width: 50%;
}

.Ritual h1{
    color: #fff;
    margin-bottom: 20px;
}

.Ritual p{
    color: #fff;
    font-family: 'PoppinsLight';
}

@media only screen and (max-width: 800px) {
    .Endo *{
        animation-name: fade;
        animation-duration: 0.2s;
    }
    .imgEndo{
        width: 100%;
    }
    .Endo .conteudo{
        width: 70%;
    position: absolute;
    background-color: #ffffffcc;
    right: 0;
    padding: 20px;
    }
    .Endo .conteudo h1{
        font-size: medium;
    }
    .Endo .conteudo p{
        font-size: 11px;
        line-height: 18px;
    }
    .Endo a{
        font-size: 12px;
        font-family: 'Roboto';
        width: 100%;
        text-align: center;
    }
    .Ritual .conteudo{
        width: 100%;
    }
}
